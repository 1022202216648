import { CloseIcon } from '@lt/components/components/ResponsiveModal/components/CloseIcon';
import React from 'react';
import styles from './closeButton.module.css';

type Props = {
  className?: string;
  onClick?(): void;
  width?: number;
  height?: number;
};

export const CloseButton = ({
  className,
  width = 24,
  height = 24,
  onClick,
}: Props) => (
  <button
    className={className || styles.wrapper}
    onClick={onClick}
    type="button"
    data-testid="CLOSE_BUTTON_TEST_ID"
  >
    <CloseIcon width={width} height={height} />
  </button>
);
