import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSlice } from '@reduxjs/toolkit';
import type { MobileAppDataProps } from 'src/components/Banners/MobileAppPopup/types';

import { getCookie } from 'src/utils/cookiesController';
import { KEY_COOKIE } from 'src/components/TopMobileAppBanner/constants';
import {
  GET_DEEPLINK,
  MOVE_TO_APP_CONTINUED_WEB,
  MOVE_TO_APP_OPENED_APP,
  MOVE_TO_APP_SHOWN,
} from './constants';
import type { BannerType } from './types';

const initialState: MobileAppDataProps = {
  shortLink: '',
  isTopBannerHiding: getCookie(KEY_COOKIE) === 'hiding',
};

const appBannerSlice = createSlice({
  name: 'bannerAppData',
  initialState,
  reducers: {
    setShortLink: (
      state,
      action: PayloadAction<MobileAppDataProps['shortLink']>,
    ) => {
      state.shortLink = action.payload;
    },

    setIsTopBannerHiding: (state, action: PayloadAction<boolean>) => {
      state.isTopBannerHiding = action.payload;
    },
  },
});

export const { setShortLink, setIsTopBannerHiding } = appBannerSlice.actions;

export const getDeeplinkAction = createAction<string>(GET_DEEPLINK);
export const moveToAppShown =
  createAction<BannerType[keyof BannerType]>(MOVE_TO_APP_SHOWN);
export const moveToAppOpenedApp = createAction<BannerType[keyof BannerType]>(
  MOVE_TO_APP_OPENED_APP,
);
export const moveToAppContinuedWeb = createAction<BannerType[keyof BannerType]>(
  MOVE_TO_APP_CONTINUED_WEB,
);

const { name, reducer } = appBannerSlice;
export { reducer as default, name };
