import React, { useEffect } from 'react';

import { useLockedBody } from 'usehooks-ts';

import { useShowBannerController } from 'src/hooks/useShowBannerController';
import { APP_BANNER_TYPES, BANNER_TYPE } from 'src/store/appBanners/constants';
import type { BannerType } from 'src/store/appBanners/types';
import MobileAppPopup from './MobileAppPopup';
import { KEY_COOKIE } from './constants';
import type { MobilePlatform } from './types';

interface MobileAppPopupContainerProps {
  linkToApp: string;
  getDeeplink: (value: string) => void;
  moveToAppOpenedAppAction: (value: BannerType[keyof BannerType]) => void;
  moveToAppShownAction: (value: BannerType[keyof BannerType]) => void;
  platform: MobilePlatform;
  moveToAppContinuedWeb: (value: BannerType[keyof BannerType]) => void;
}

/*
 * Показываем каждый раз, когда пользователь заходит на сайт, открыв заново браузер
 */

export const MobileAppPopupContainer = (
  props: MobileAppPopupContainerProps,
) => {
  const [, setLocked] = useLockedBody(false);

  const {
    linkToApp,
    getDeeplink,
    moveToAppOpenedAppAction,
    moveToAppShownAction,
    platform,
    moveToAppContinuedWeb,
  } = props;

  const rating = platform === 'android' ? 4.6 : 5;
  const reviews = platform === 'android' ? '5 тыс.' : '10 тыс.';

  const { isShowBanner, handleCloseBanner } =
    useShowBannerController(KEY_COOKIE);

  const handleClose = () => {
    handleCloseBanner();
    moveToAppOpenedAppAction(BANNER_TYPE.MOBILE_BANNER_TO_APP);
    setLocked(false);
  };

  const handleCloseClickOutside = () => {
    handleCloseBanner();
    moveToAppContinuedWeb(BANNER_TYPE.MOBILE_BANNER_TO_APP);
    setLocked(false);
  };

  useEffect(() => {
    if (isShowBanner) {
      setLocked(true);
      getDeeplink(APP_BANNER_TYPES.BANNER_MOBWEB);
    }
  }, [isShowBanner, setLocked, getDeeplink]);

  if (!linkToApp || !isShowBanner) {
    return null;
  }

  return (
    <MobileAppPopup
      onClickButton={handleClose}
      rating={rating}
      reviews={reviews}
      linkToApp={linkToApp}
      trackShow={moveToAppShownAction}
      visible={isShowBanner}
      onClick={handleCloseClickOutside}
    />
  );
};
