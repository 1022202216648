import URIjs from 'urijs';
import { pickBy } from 'lodash';
import { isSSR } from 'src/helpers/ssr';
import { MODULE_NAME } from '../constants';

/**
 * Текущий урл
 */
export const currentUri = URIjs();

export const updateURL = () => {
  const clientUrl = URIjs.decode(currentUri.toString());
  window.history.replaceState(null, document.title, clientUrl);
};

export const getUrlParams = () =>
  isSSR
    ? {}
    : Object.fromEntries(
        new URLSearchParams(new URL(window.location.href).search),
      );

/**
 * Проверяет, относится ли ссылка у отелю
 * @example
 * '/hotels/9019372-Kleopatra_Tac_Hotel' // true
 */
export const isHotelUrl = (url: string): boolean => {
  const regex = new RegExp(MODULE_NAME.HOTEL);

  try {
    const { pathname } = new URL(url);
    return regex.test(pathname);
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const isTbankCreditPage = () =>
  new RegExp(MODULE_NAME.TBANK_CREDIT).test(window.location.pathname);

const transformCityNameForUrl = (cityName: string): string => {
  const cityNameWithPlus = cityName.split('-').join('+');
  const splitCityName = cityNameWithPlus.split(' ');
  if (splitCityName.length > 1) {
    return splitCityName.join('.');
  }
  return cityNameWithPlus;
};

export const resetCityInUrl = (cityName) => {
  const clientUrl = URIjs.decode(currentUri.toString());
  const cityNameForUrl = transformCityNameForUrl(cityName);
  return clientUrl.replace(cityNameForUrl, 'Any');
};

export const addQueryParam = (name: string, value: string) => {
  const newUrl = currentUri.setSearch(name, value);
  window.history.replaceState(null, document.title, newUrl.href());
};

export const isFromMobileAppFlag = (): boolean =>
  getUrlParams().from_app === 'true';

export const objToQueryString = (obj): string => {
  // удаляем ключи объекта, у которых нет значений или они пустые
  const prepare = pickBy(obj);

  return new URLSearchParams(prepare).toString();
};
