import React from 'react';
import { Link } from '@lt/components';
import { i18n } from '@lt/localization';
import Column from '../Column';

const CompanyColumn = () => (
  <Column title={i18n.t('footer.company.title')}>
    <li>
      <Link href="/about">{i18n.t('footer.company.aboutUs')}</Link>
    </li>
    <li>
      <Link href="https://career.level.travel/">
        {i18n.t('footer.company.career')}
      </Link>
    </li>
    <li>
      <Link href="/testimonials">{i18n.t('footer.company.reviews')}</Link>
    </li>
    <li>
      <Link href="https://press.level.travel/">
        {i18n.t('footer.company.press')}
      </Link>
    </li>
    <li>
      <Link href="/contacts">{i18n.t('footer.company.contacts')}</Link>
    </li>
    <li>
      <Link href="https://promo.level.travel/co-promo">
        {i18n.t('footer.company.coPromo')}
      </Link>
    </li>
  </Column>
);

export default CompanyColumn;
