import { createPortal } from 'react-dom';
import React, { useEffect } from 'react';
import { APP_BANNER_TYPES } from 'src/store/appBanners/constants';
import { isSSR } from '../../../../helpers/ssr';

import MobileAppBanner from '../../../MobileAppBanner/MobileAppBanner';

type Props = {
  appLink: string;
  getShortLink: (value: string) => void;
};

const FooterAppBanners = (props: Props): JSX.Element | null => {
  const { appLink, getShortLink } = props;
  useEffect(() => {
    if (!appLink) getShortLink(APP_BANNER_TYPES.BANNER_MOBWEB);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isSSR) {
    return null;
  }

  const NODE = {
    android: document.getElementById('google_play_link--footer'),
    ios: document.getElementById('app_store_link--footer'),
    rustore: document.getElementById('ru_store_link--footer'),
    appgallery: document.getElementById('app_gallery_link--footer'),
  };

  return (
    <>
      {Object.entries(NODE).map(([os, element]) => {
        if (element && appLink)
          return createPortal(
            <MobileAppBanner
              deviceType={os as keyof typeof NODE}
              appLink={appLink}
            />,
            element,
          );
        return null;
      })}
    </>
  );
};

export default FooterAppBanners;
