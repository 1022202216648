import React, { useEffect } from 'react';

import { Link } from '@lt/components';
import { i18n } from '@lt/localization';
import { useSelector } from 'react-redux';
import { getMobilePlatform } from 'src/store/view/selectors';
import { APP_BANNER_TYPES } from 'src/store/appBanners/constants';
import styles from './DownloadAppSection.module.css';
import { getStoreTypesByPlatform, ICONS } from './constants';

type Props = {
  appLink: string;
  isMobile: boolean;
  getLink: (value: string) => void;
};

const DownloadAppSection = (props: Props) => {
  const { appLink, isMobile, getLink } = props;

  const platform = useSelector(getMobilePlatform);

  useEffect(() => {
    if (!appLink)
      getLink(
        isMobile
          ? APP_BANNER_TYPES.BANNER_MOBWEB
          : APP_BANNER_TYPES.BANNER_DESKTOP,
      );
  }, [appLink, getLink, isMobile]);

  return (
    <section>
      <h3 className={styles.title}>{i18n.t('footer.downloadOurApp')}</h3>
      <div className={styles.goToStoresContainer}>
        {getStoreTypesByPlatform(
          platform === 'ios',
          platform === 'android',
        ).map((type) => {
          const Icon = ICONS[type];
          return (
            <Link href={appLink} key={type}>
              <Icon width={150} height={47} />
            </Link>
          );
        })}
      </div>
    </section>
  );
};
export default DownloadAppSection;
