import { dataLayerPush } from 'src/helpers/gtm';

export const pushTopBannerClosed = () => {
  dataLayerPush({ event: 'app_prices_are_lower_closed' });
};

export const pushTopBannerClicked = () => {
  dataLayerPush({ event: 'app_prices_are_lower_go' });
};

export const pushTopBannerShown = () => {
  dataLayerPush({ event: 'app_prices_are_lower_shown' });
};
