/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { i18n } from '@lt/localization';
import React, { useEffect, useState } from 'react';

import MobileAppBanner from 'src/components/MobileAppBanner';
import Modal from 'src/components/Modal';

import { NBSP } from 'src/constants/common';

import { Monitor } from '@lt/components/icons-react/sidebar';
import { APP_BANNER_TYPES } from 'src/store/appBanners/constants';
import { setMobileCookie } from 'src/utils/mobileUtils';
import { Link } from '@lt/components';
import { useFreezeBody } from '@lt/components/hooks';
import classnames from 'classnames';
import { getMobileMenu } from '../../utils/getMobileMenu';

import { MOBILE_PLATFORMS, WRAPPER_TEST_ID } from './constants';
import type { ISideNavMenu } from './types';

import styles from './sideNavMenu.module.css';

export const SideNavMenu = ({
  isWhitelabel = false,
  closeSidebar,
  onShowAuthPopup,
  onLogOut,
  isLoggedIn = false,
  onOpenWriteUsPopup,
  isHideFeedback,
  contacts,
  deviceType,
  wishlistCount,
  appLink,
  getDeeplink,
  isWebview,
  isAppBannerEnabled,
}: ISideNavMenu): JSX.Element => {
  const [isOpenedMobileMenu, setIsOpenedMobileMenu] = useState(false);

  const { setIsFreeze } = useFreezeBody(false);

  useFreezeBody(isOpenedMobileMenu);

  useEffect(() => {
    /**
     * Добавлено для анимации(через 200мс добавляется свойство transform)
     */
    setIsOpenedMobileMenu(true);
    setIsFreeze(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!appLink) getDeeplink(APP_BANNER_TYPES.BANNER_MOBWEB);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onToggle = () => {
    setIsOpenedMobileMenu(false);
    /**
     * Скрываем сайдбар только через 200мс, чтобы появилась анимация
     */
    const closeTimeoutId = setTimeout(() => {
      closeSidebar();
      clearTimeout(closeTimeoutId);
    }, 200);
    setIsFreeze(false);
  };

  const goToDesktopVersion = () => {
    setMobileCookie(true);
    window.location.reload();
  };

  const menu = getMobileMenu({
    isWhitelabel,
    isLoggedIn,
    onToggle,
    onShowAuthPopup,
    onLogOut,
    onShowFeedbackPopup: onOpenWriteUsPopup,
    wishlistCount,
    customContacts: contacts,
  }).filter((item) => item.active);
  return (
    <Modal>
      <div className={styles.container} data-testid={WRAPPER_TEST_ID}>
        <div
          className={classnames(styles.sidebarWrapper, {
            [styles.openedSidebar]: isOpenedMobileMenu,
          })}
        >
          <div className={styles.image} />

          <div className={styles.menuWrapper}>
            <div className={styles.menuTopPart}>
              {menu.map(
                ({
                  icon,
                  text,
                  href,
                  external,
                  onClick,
                  dataTestId,
                  secondIcon,
                }) => (
                  <Link
                    href={href || ''}
                    key={text}
                    target={external ? '_blank' : '_self'}
                    rel="noreferrer"
                    onClick={onClick || (() => null)}
                    data-testid={dataTestId}
                    className={styles.menuLink}
                  >
                    <div className={styles.icon}>{icon}</div>
                    <span className={styles.menuText}>{text}</span>
                    {secondIcon}
                  </Link>
                ),
              )}
              {!isWebview && (
                <div className={styles.fullVersionContainer}>
                  <div className={styles.menuItem} onClick={goToDesktopVersion}>
                    <div className={styles.icon}>
                      <Monitor />
                    </div>
                    <span className={styles.menuText}>
                      {i18n.t('headerMenu.mobile.full_ver')}
                    </span>
                  </div>
                  {!isHideFeedback && (
                    <Link className={styles.menuLink} href="/agreements">
                      <span className={styles.menuText}>
                        <span className={styles.bottomLink}>
                          {i18n.t('headerMenu.mobile.privacy', { nbsp: NBSP })}
                        </span>
                      </span>
                    </Link>
                  )}
                </div>
              )}

              {isAppBannerEnabled &&
                deviceType === MOBILE_PLATFORMS.ANDROID && (
                  <div className={styles.mobileStoreContainer}>
                    <MobileAppBanner
                      sidebarMenu
                      appLink={appLink}
                      deviceType={MOBILE_PLATFORMS.ANDROID}
                    />
                    <MobileAppBanner
                      sidebarMenu
                      deviceType={MOBILE_PLATFORMS.RUSTORE}
                      appLink={appLink}
                    />
                    <MobileAppBanner
                      sidebarMenu
                      deviceType={MOBILE_PLATFORMS.APPGALLERY}
                      appLink={appLink}
                    />
                  </div>
                )}
              {isAppBannerEnabled && deviceType === MOBILE_PLATFORMS.IOS && (
                <div className={styles.mobileStoreContainer}>
                  <MobileAppBanner
                    sidebarMenu
                    deviceType={MOBILE_PLATFORMS.IOS}
                    appLink={appLink}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.sideNavBg} onClick={onToggle} />
      </div>
    </Modal>
  );
};
