import React, { useEffect } from 'react';
import { useShowBannerController } from 'src/hooks/useShowBannerController';
import type { BannerType } from 'src/store/appBanners/types';
import { APP_BANNER_TYPES, BANNER_TYPE } from 'src/store/appBanners/constants';
import { TopMobileAppBanner } from './TopMobileAppBanner';
import type { MobilePlatform } from '../Banners/MobileAppPopup/types';
import { KEY_COOKIE } from './constants';
import {
  pushTopBannerClicked,
  pushTopBannerClosed,
  pushTopBannerShown,
} from './analytics';

interface ITopMobileAppBannerContainer {
  linkToApp: string;
  getDeeplink: (value: string) => void;
  moveToAppOpenedAppAction: (value: BannerType[keyof BannerType]) => void;
  moveToAppShownAction: (value: BannerType[keyof BannerType]) => void;
  platform: MobilePlatform;
  moveToAppContinuedWeb: (value: BannerType[keyof BannerType]) => void;
  setBannerHiding: (visible: boolean) => void;
}

export const TopMobileAppBannerContainer = (
  props: ITopMobileAppBannerContainer,
): JSX.Element | null => {
  const {
    linkToApp,
    getDeeplink,
    moveToAppOpenedAppAction,
    moveToAppShownAction,
    platform,
    moveToAppContinuedWeb,
    setBannerHiding,
  } = props;

  const rating = platform === 'android' ? 4.6 : 5;
  const reviews = platform === 'android' ? '5 тыс.' : '10 тыс.';

  const { isShowBanner, handleCloseBanner } = useShowBannerController(
    KEY_COOKIE,
    3,
  );

  const handleClick = () => {
    pushTopBannerClicked();
    handleCloseBanner();
    setBannerHiding(true);
    moveToAppOpenedAppAction(BANNER_TYPE.UPPER_MOBILE_BANNER_TO_APP);
  };

  const handleCloseClick = () => {
    pushTopBannerClosed();
    handleCloseBanner();
    setBannerHiding(true);
    moveToAppContinuedWeb(BANNER_TYPE.UPPER_MOBILE_BANNER_TO_APP);
  };

  useEffect(() => {
    if (isShowBanner) {
      getDeeplink(APP_BANNER_TYPES.UPPER_BANNER_MOBWEB);
      pushTopBannerShown();
    }
  }, [isShowBanner, getDeeplink]);

  if (!linkToApp || !isShowBanner) {
    return null;
  }

  return (
    <TopMobileAppBanner
      onClickButton={handleClick}
      rating={rating}
      reviewsCountText={reviews}
      linkToApp={linkToApp}
      trackShow={moveToAppShownAction}
      onClose={handleCloseClick}
    />
  );
};
