import { connect } from 'react-redux';
import { getShortLink } from 'src/store/appBanners/selectors';
import { getDeeplinkAction } from 'src/store/appBanners/slice';
import { getIsMobile } from 'src/store/view/selectors';
import DownloadAppSection from './DownloadAppSection';

const mapStateToProps = (state) => ({
  appLink: getShortLink(state),
  isMobile: getIsMobile(state),
});

const mapDispatchToProps = {
  getLink: getDeeplinkAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAppSection);
