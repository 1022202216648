import { takeLatest, select } from 'redux-saga/effects';

import { api } from 'src/api';
import type { SagaReturnType } from 'redux-saga/effects';
import type { PayloadAction } from '@reduxjs/toolkit';

import { APPLICATION_VERSION } from 'src/error/config/sentry/runTimeConfig';
import type { MobilePlatform } from 'src/components/Banners/MobileAppPopup/types';
import { getSearchType } from 'src/routes/Hotel/store/selectors/view';
import type { BannerEvent, BannerType } from '@lt/api/dist/methods/stats/types';

import { dataLayerPush } from 'src/helpers/gtm';
import { getIsMobile, getMobilePlatform } from '../view/selectors';
import { getClientStatsData } from '../client/selectors';
import {
  moveToAppContinuedWeb,
  moveToAppOpenedApp,
  moveToAppShown,
} from '../appBanners/slice';
import { CLICK_ON_BANNER, CLOSE_BANNER, SHOW_BANNER } from './constants';

// Продуктовая аналитика для моб баннера

function isBannerShown() {
  dataLayerPush({
    event: 'move_to_app_shown',
  });
}

function onClickAppBanner() {
  dataLayerPush({
    event: 'move_to_app_opened_app',
  });
}

function onClickStayButton() {
  dataLayerPush({
    event: 'move_to_app_continued_web',
  });
}

// Техническая аналитика для моб баннера

function* getCommonAnalyticsData(
  eventType: BannerEvent[keyof BannerEvent],
  banner_type: BannerType[keyof BannerType],
) {
  const url = window?.location?.href;
  const { userAgent } = window.navigator;
  const isMobile: SagaReturnType<typeof getIsMobile> = yield select(
    getIsMobile,
  );
  const client: SagaReturnType<typeof getClientStatsData> = yield select(
    getClientStatsData,
  );
  const platform: MobilePlatform = yield select(getMobilePlatform);
  let searchType: 'package' | 'hotel' | undefined;
  try {
    searchType = yield select(getSearchType);
  } catch (error) {
    searchType = undefined;
  }

  return {
    url,
    banner_type,
    event: eventType,
    client,
    is_mobile: isMobile,
    release_version: APPLICATION_VERSION ?? '',
    search_type: searchType,
    platform,
    user_agent: userAgent,
  };
}

// показ баннера
function* isAppBannerShownTechAnalyticsWorker(
  action: PayloadAction<BannerType[keyof BannerType]>,
) {
  try {
    const analyticsData: SagaReturnType<typeof getCommonAnalyticsData> =
      yield getCommonAnalyticsData(SHOW_BANNER, action.payload);
    yield api.postStatsBanner(analyticsData);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

// переход по баннеру
function* onClickAppBannerTechAnalyticsWorker(
  action: PayloadAction<BannerType[keyof BannerType]>,
) {
  try {
    const analyticsData: SagaReturnType<typeof getCommonAnalyticsData> =
      yield getCommonAnalyticsData(CLICK_ON_BANNER, action.payload);
    yield api.postStatsBanner(analyticsData);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

// закрытие баннера
function* onClickStayButtonTechAnalyticsWorker(
  action: PayloadAction<BannerType[keyof BannerType]>,
) {
  try {
    const analyticsData: SagaReturnType<typeof getCommonAnalyticsData> =
      yield getCommonAnalyticsData(CLOSE_BANNER, action.payload);
    yield api.postStatsBanner(analyticsData);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export default function* appBannersSagaWatcher() {
  yield takeLatest(moveToAppOpenedApp, onClickAppBanner);
  yield takeLatest(moveToAppContinuedWeb, onClickStayButton);
  yield takeLatest(moveToAppShown, isBannerShown);
  yield takeLatest(moveToAppShown, isAppBannerShownTechAnalyticsWorker);
  yield takeLatest(moveToAppOpenedApp, onClickAppBannerTechAnalyticsWorker);
  yield takeLatest(moveToAppContinuedWeb, onClickStayButtonTechAnalyticsWorker);
}
