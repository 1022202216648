import {
  getAdultsCount,
  getHotelId,
  getMeal,
  getNetPrice,
  getNightsCount,
  getOperatorName,
  getRegionNameRu,
  getRoomInfo,
  getStartDate,
  getTouristsCount,
  makeGetKids,
} from 'src/routes/Checkout/store/selectors/package';
import {
  getHotelId as getHotelIdHotelPage,
  getHotelStars as getHotelStarsHotelPage,
} from 'src/routes/Hotel/store/selectors/hotel';

import {
  getArrivalToDateFromFlight,
  getOriginDepartureFromFlight,
} from 'src/routes/Checkout/store/selectors/analytics';
import { getRemainingPaymentAmount } from 'src/routes/Checkout/store/selectors/order';
import { formatServerDate } from 'src/utils/dateUtils';
import { getDefaultPrice } from 'src/routes/Hotel/store/selectors/common';
import { resetPhoneFormat } from 'src/components/AuthNew/utils';
import { createSelector } from '@reduxjs/toolkit';
import { getHotelStars } from 'src/routes/Checkout/store/selectors/hotel';
import {
  getClientEmail,
  getClientId,
  getClientIsSubscribedMail,
  getClientName,
  getClientPhone,
  getClientSurname,
  getClientUnconfirmedEmail,
  getClientUnconfirmedPhone,
} from '../client/selectors';
import { getIsWhitelabel, searchTypeSelector } from '../view/selectors';
import {
  getSearchParamsAdults,
  getSearchParamsFlexDates,
  getSearchParamsFromCity,
  getSearchParamsFromCountryNameRu,
  getSearchParamsKidsAges,
  getSearchParamsKidsCount,
  getSearchParamsNights,
  getSearchParamsStartDate,
  getSearchParamsToCountryNameRu,
} from '../enqueue/selectors';

export const getCustomerAction = () => ({
  customFields: {
    whiteLabel: true,
    nameWhitelabel: window.PARTNER_INFO.name,
  },
});

export const getMindboxAuthData = createSelector(
  [
    getIsWhitelabel,
    getClientPhone,
    getClientUnconfirmedPhone,
    getClientEmail,
    getClientUnconfirmedEmail,
  ],
  (
    isWhiteLabel,
    clientPhone,
    clientUnconfirmedPhone,
    clientEmail,
    clientUnconfirmedEmail,
  ) => {
    const data = {
      customer: {
        mobilePhone: resetPhoneFormat(clientPhone || clientUnconfirmedPhone),
        email: clientEmail || clientUnconfirmedEmail,
      },
      customerAction: {},
    };
    if (isWhiteLabel) data.customerAction = getCustomerAction();
    return data;
  },
);

export const getMindboxRegistrationData = createSelector(
  [
    getIsWhitelabel,
    getClientId,
    getClientName,
    getClientSurname,
    getClientPhone,
    getClientUnconfirmedPhone,
    getClientEmail,
    getClientUnconfirmedEmail,
    getClientIsSubscribedMail,
  ],
  (
    isWhiteLabel,
    clientIdLT,
    name,
    surname,
    clientPhone,
    clientUnconfirmedPhone,
    clientEmail,
    clientUnconfirmedEmail,
    isSubscribed,
  ) => {
    const data = {
      customer: {
        ids: {
          clientIdLT,
        },
        name,
        surname,
        mobilePhone: resetPhoneFormat(clientPhone || clientUnconfirmedPhone),
        email: clientEmail || clientUnconfirmedEmail,
        subscriptions: [
          {
            pointOfContact: 'Email',
            topic: 'Trigger',
            isSubscribed: true,
          },
          {
            pointOfContact: 'Email',
            topic: 'Spec',
            isSubscribed: true,
          },
          {
            pointOfContact: 'Email',
            topic: 'Mass',
            isSubscribed: true,
          },
          {
            pointOfContact: 'Email',
            topic: 'Newsletter',
            isSubscribed,
          },
        ],
      },
      customerAction: {},
    };
    if (isWhiteLabel) data.customerAction = getCustomerAction();
    return data;
  },
);

export const getMindboxAuthOnCheckoutData = createSelector(
  [
    getIsWhitelabel,
    getClientEmail,
    getClientUnconfirmedEmail,
    getClientPhone,
    getClientUnconfirmedPhone,
    getHotelId,
    getAdultsCount,
    getSearchParamsFromCountryNameRu,
    getSearchParamsToCountryNameRu,
    getOriginDepartureFromFlight,
    getStartDate,
    getSearchParamsFlexDates,
    makeGetKids,
    getNightsCount,
    getRoomInfo,
    getOperatorName,
    getRegionNameRu,
    getRemainingPaymentAmount,
    getNetPrice,
    searchTypeSelector,
  ],
  (
    isWhiteLabel,
    clientPhone,
    clientUnconfirmedPhone,
    clientEmail,
    clientUnconfirmedEmail,
    hotelId,
    adultsCount,
    fromCountry,
    toCountry,
    departureCity,
    startDate,
    flexDates,
    kids,
    nightsCount,
    roomInfo,
    operatorName,
    regionName,
    fullAmount,
    netAmount,
    searchType,
  ) => {
    const data = {
      customer: {
        mobilePhone: resetPhoneFormat(clientPhone || clientUnconfirmedPhone),
        email: clientEmail || clientUnconfirmedEmail,
      },
      viewProduct: {
        product: {
          ids: {
            sajtLevelTravel: hotelId,
          },
        },
        customerAction: {
          customFields: {
            adultsCount,
            country: [fromCountry, toCountry],
            departureCity,
            startDate,
            flexDates,
            kids,
            nightsCount,
            numberType: roomInfo.room_type_id,
            operatorName,
            regionName,
            fullAmount,
            netAmount,
            price: fullAmount || netAmount,
            searchType,
            page: window.location.href,
          },
        },
      },
    };
    if (isWhiteLabel)
      data.viewProduct.customerAction = {
        ...data.viewProduct.customerAction,
        ...getCustomerAction().customFields,
      };

    return data;
  },
);

export const getMindboxFillTouristData = createSelector(
  [getIsWhitelabel, getClientId],
  (isWhiteLabel, clientIdLT) => {
    const data = {
      customer: {
        ids: {
          clientIdLT,
        },
      },
      customerAction: {},
    };
    if (isWhiteLabel) data.customerAction = getCustomerAction();
    return data;
  },
);

export const getMindboxPageviewCheckoutData = createSelector(
  [
    getIsWhitelabel,
    getHotelId,
    getRemainingPaymentAmount,
    getNetPrice,
    getTouristsCount,
    getNightsCount,
    makeGetKids,
    getAdultsCount,
    getStartDate,
    getArrivalToDateFromFlight,
    getOperatorName,
    getSearchParamsFromCountryNameRu,
    getSearchParamsToCountryNameRu,
    getRoomInfo,
    getMeal,
    getOriginDepartureFromFlight,
    getRegionNameRu,
    getHotelStars,
    searchTypeSelector,
    getSearchParamsFlexDates,
  ],
  (
    isWhiteLabel,
    hotelId,
    paymentAmount,
    netPrice,
    touristsCount,
    nights_count,
    kids_ages,
    adults_count,
    flight_date,
    arival_date,
    operator_name,
    fromCountry,
    toCountry,
    number_type,
    food_type,
    departure_city,
    region,
    stars,
    search_type,
    flexDates,
  ) => {
    const data = {
      viewProduct: {
        product: {
          ids: {
            sajtLevelTravel: hotelId,
          },
        },
        customerAction: {
          customFields: {
            price: paymentAmount || netPrice,
            seen_price_per_night_person: Math.ceil(
              (paymentAmount || netPrice) / touristsCount / nights_count,
            ),
            touristsCount,
            kids_count: kids_ages.length,
            kids_ages,
            adults_count,
            flight_date,
            arival_date: formatServerDate(arival_date),
            operator_name,
            country: [fromCountry, toCountry],
            number_type,
            food_type,
            departure_city,
            region,
            nights_count,
            stars,
            search_type,
            flexDates,
            page: window.location.href,
          },
        },
      },
    };

    if (isWhiteLabel)
      data.viewProduct.customerAction = {
        ...data.viewProduct.customerAction,
        ...getCustomerAction().customFields,
      };
    return data;
  },
);

export const getMindboxPageviewHotelData = createSelector(
  [
    getIsWhitelabel,
    getDefaultPrice,
    getSearchParamsKidsCount,
    getSearchParamsAdults,
    getSearchParamsNights,
    getHotelIdHotelPage,
    getHotelStarsHotelPage,
    getSearchParamsFromCity,
    getSearchParamsFromCountryNameRu,
    getSearchParamsToCountryNameRu,
    getSearchParamsStartDate,
    getSearchParamsKidsAges,
    searchTypeSelector,
  ],
  (
    isWhiteLabel,
    min_price,
    adults_count = 0,
    kids_count,
    nights,
    hotelId,
    stars,
    departure_city,
    fromCountry,
    toCountry,
    flight_date,
    kids_ages,
    search_type,
  ) => {
    const seen_price_per_night_person = min_price
      ? Math.round(
          min_price / (adults_count + (kids_count || 0)) / (nights || 0),
        )
      : 0;

    const data = {
      viewProduct: {
        product: {
          sku: {
            ids: {
              sajtLevelTravel: hotelId,
            },
          },
          customFields: {
            stars,
          },
        },
        customerAction: {
          customFields: {
            page: window.location.href,
            seen_price_per_night_person,
            min_price,
            adults_count,
            kids_count,
            nights,
            departure_city,
            country: [fromCountry, toCountry],
            flight_date,
            kids_ages,
            search_type,
          },
        },
      },
    };

    if (isWhiteLabel)
      data.viewProduct.customerAction = {
        ...data.viewProduct.customerAction,
        ...getCustomerAction().customFields,
      };
    return data;
  },
);
