export const GO_MOBILE_APP_POPUP = 'GO_MOBILE_APP_POPUP';
export const MOVE_TO_APP_SHOWN = 'MOVE_TO_APP_SHOWN';
export const MOVE_TO_APP_OPENED_APP = 'MOVE_TO_APP_OPENED_APP';
export const MOVE_TO_APP_CONTINUED_WEB = 'MOVE_TO_APP_CONTINUED_WEB';
export const GET_DEEPLINK = 'GET_DEEPLINK';
export const GET_BANNER_TYPE = 'GET_BANNER_TYPE';
export const PARTNER_KEY_COOKIE = 'lt_ref';
export const UTM_KEY_COOKIE = '__utmz';
export const AFFMARKET_PARAMS = 'affmarker';
export const utm_marker = new Map([
  ['utmcsr', 'utm_source'],
  ['utmccn', 'utm_campaign'],
  ['utmcmd', 'utm_medium'],
  ['utmctr', 'utm_keyword'],
  ['utmcct', 'utm_content'],
]);
export const APP_BANNER_TYPES = {
  BANNER_DESKTOP: 'banner_desktop',
  BANNER_MOBWEB: 'banner_mobweb',
  UPPER_BANNER_MOBWEB: 'upper_banner_mobweb',
};

export const BANNER_TYPE = {
  MOBILE_BANNER_TO_APP: 'mobile_banner_to_app',
  UPPER_MOBILE_BANNER_TO_APP: 'upper_mobile_banner_to_app',
  DESKTOP_BANNER_TO_APP: 'desktop_banner_to_app',
};
