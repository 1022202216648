import { connect } from 'react-redux';
import { getMobilePlatform } from 'src/store/view/selectors';
import { getShortLink } from 'src/store/appBanners/selectors';
import {
  getDeeplinkAction,
  moveToAppContinuedWeb,
  moveToAppOpenedApp,
  moveToAppShown,
  setIsTopBannerHiding,
} from 'src/store/appBanners/slice';
import { TopMobileAppBannerContainer } from './TopMobileAppBannerContainer';

const mapStateToProps = (state) => ({
  linkToApp: getShortLink(state),
  platform: getMobilePlatform(state),
});

const mapDispatchToProps = {
  setBannerHiding: setIsTopBannerHiding,
  getDeeplink: getDeeplinkAction,
  moveToAppOpenedAppAction: moveToAppOpenedApp,
  moveToAppShownAction: moveToAppShown,
  moveToAppContinuedWeb,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(TopMobileAppBannerContainer);
